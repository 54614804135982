import {Place, Element} from '@wix/ambassador-seating-plans/types'
import {FeeType} from '@wix/events-types'
import {getFormattedPrice} from '@wix/wix-events-commons-statics'
import React, {useState} from 'react'
import {Modal} from '../modal'
import {getSelectedTicketQuantity} from '../../../selectors/selected-tickets'
import s from './seating-plan.scss'
import {Footer} from './footer'
import {Tickets} from './tickets'
import {Plan} from './plan'
import {Loader} from './loader'
import {SeatingPlanProps, SelectedSeat, Legend} from '.'

export const SeatingPlan = ({plan, tickets, selectTicket, unselectTicket, selectedTickets}: SeatingPlanProps) => {
  const [selectedSeat, setSelectedSeat] = useState<SelectedSeat>(null)
  const [selectedSeats, setSelectedSeats] = useState<SelectedSeat[]>([])
  const [showTickets, setShowTickets] = useState(false)
  const [legend, setLegend] = useState<Legend[]>([])
  const [showLoader, setShowLoader] = useState(false)

  React.useEffect(() => {
    const legendItems = plan.categories.map(({externalId, config: {color}}) => {
      const {price} = tickets.find(
        ticketDef => ticketDef.id === externalId.replace('wix.events.v1.ticket_definition:', ''),
      )
      return {
        color,
        price: getFormattedPrice(Number(price.value), price.currency),
      }
    })

    setLegend(legendItems)
  }, [plan, tickets])

  const handleCheckout = () => {
    setShowLoader(true)
  }

  const handleAddClick = () => {
    const ticketId = selectedSeat.ticketId

    setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, selectedSeat])
    selectTicket({
      ticketId,
      count: getSelectedTicketQuantity(selectedTickets, ticketId) + 1,
    })
    setSelectedSeat(null)
  }

  const handleRemove = (id: string, ticketId: string) => {
    unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1})
    setSelectedSeats(prevSelectedSeats => prevSelectedSeats.filter(prevSelectedSeat => prevSelectedSeat.id !== id))
  }

  const onSeatClick = (seat: Place, categoryId: number, definition: Element) => {
    const {
      externalId,
      config: {color},
    } = plan.categories.find(category => category.id === categoryId)
    const ticketDefinition = tickets.find(
      ticketDef => ticketDef.id === externalId.replace('wix.events.v1.ticket_definition:', ''),
    )
    const sectorTitle = plan.sectors.find(sector => definition.sectorId === sector.id)?.title ?? ''

    if (selectedSeats.find(selected => selected.id === seat.id)) {
      handleRemove(seat.id, ticketDefinition.id)
    }

    setSelectedSeat({
      id: seat.id,
      sector: sectorTitle,
      seat: seat.label,
      row: definition.title,
      ticketPrice: getFormattedPrice(Number(ticketDefinition.price.value), ticketDefinition.price.currency),
      fees: ticketDefinition.wixFeeConfig.type === FeeType.FEE_ADDED_AT_CHECKOUT ? 'Fee' : '',
      ticketName: ticketDefinition.name,
      ticketId: ticketDefinition.id,
      color,
    })
  }

  return (
    <Modal skin={showLoader ? undefined : 'grey'} closeButtonAsButton={!showLoader}>
      {showLoader ? (
        <Loader />
      ) : (
        <div className={s.container}>
          <Tickets
            selectedSeats={selectedSeats}
            showTickets={showTickets}
            onBackClick={() => setShowTickets(false)}
            onRemoveClick={handleRemove}
            onCheckoutClick={handleCheckout}
          />
          <Plan
            selectedSeat={selectedSeat}
            selectedSeats={selectedSeats}
            plan={plan}
            legend={legend}
            onSeatClick={onSeatClick}
          />
          <Footer
            selectedSeat={selectedSeat}
            selectedSeats={selectedSeats}
            onAddClick={handleAddClick}
            onCloseClick={() => setSelectedSeat(null)}
            onTicketsClick={() => setShowTickets(true)}
            onCheckoutClick={handleCheckout}
          />
        </div>
      )}
    </Modal>
  )
}
